<template>
  <Menu as="div" class="relative inline-block w-full my-auto text-left">
    <div>
      <MenuButton
        class="flex items-center justify-between w-full h-10 px-3 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light lg:rounded-md focus:ring-offset-primary-light"
        :class="
          darkMode
            ? 'text-header-font lg:hover:bg-header-dark'
            : 'text-header-dark hover:text-header-darkest hover:bg-header-off-white border border-header-lightest'
        "
      >
        <span class="text-sm font-medium truncate">
          <span class="sr-only">Open customer menu for </span>
          {{ authCustomerTeamMember.customer.name }}
        </span>
        <ChevronDownIcon class="block w-5 h-5 ml-1 shrink-0" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="scale-95 opacity-0"
      enter-to-class="scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="scale-100 opacity-100"
      leave-to-class="scale-95 opacity-0"
    >
      <MenuItems
        class="absolute max-h-screen-75 overflow-y-scroll left-0 z-30 w-56 mt-1 origin-top-left bg-white divide-y rounded-md shadow-xl divide-header-off-white-dark ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <p class="px-4 pt-2 pb-1 text-sm text-heading">Your current customer is</p>
          <div class="flex items-center px-4 py-2 text-sm font-bold text-gray-900">
            <UserGroupIcon class="w-5 h-5 mr-3 text-gray-500" aria-hidden="true" />
            <p class="truncate">{{ authCustomerTeamMember.customer.name }}</p>
          </div>
        </div>
        <div v-if="hasOtherCustomerTeamMembers" class="py-1">
          <p class="px-4 py-2 text-sm text-primary">Switch customer to</p>
          <MenuItem
            v-for="customerTeamMember in otherCustomerTeamMembers"
            :key="customerTeamMember.id"
            v-slot="{ active }"
            @click.prevent="switchSelectedCustomerTeamMember(customerTeamMember.id)"
          >
            <div
              :class="[
                active ? 'bg-header-off-white text-gray-900' : 'text-gray-700',
                'group flex items-center px-4 py-2 text-sm cursor-pointer hover:text-gray-500',
              ]"
            >
              <UserGroupIcon
                class="w-5 h-5 mr-3 text-gray-500 group-hover:text-gray-700"
                aria-hidden="true"
              />
              <p class="truncate">{{ customerTeamMember.customer.name }}</p>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { computed } from 'vue'
import { router } from '@inertiajs/vue3'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon, UserGroupIcon } from '@heroicons/vue/solid'

import { useAuth } from '@/Hooks/Customer/useAuth'

defineProps({
  darkMode: { type: Boolean, default: true },
})

const { authCustomerTeamMember, authCustomerTeamMembers } = useAuth()

const otherCustomerTeamMembers = computed(() => {
  return authCustomerTeamMembers.value.data
    .filter((customerTeamMember) => customerTeamMember.id !== authCustomerTeamMember.value.id)
    .filter((customerTeamMember) => customerTeamMember.customer)
    .sort((a, b) => {
      return a.customer?.name.localeCompare(b.customer?.name)
    })
})

const hasOtherCustomerTeamMembers = computed(() => otherCustomerTeamMembers.value.length > 0)

const switchSelectedCustomerTeamMember = async (selectedCustomerTeamMemberId) => {
  router.post(route('web.customer.auth-customer-user.selected-customer-team-member.update'), {
    customer_team_member_id: selectedCustomerTeamMemberId,
  })
}
</script>
