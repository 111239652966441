<template>
  <div class="pt-6 pb-12 space-y-6">
    <div class="flex flex-col mx-6 space-y-3">
      <Button
        v-if="authCustomerOrganization.is_pro_account"
        :link="tenant.company.order_landing_url"
        openInNewTab
        text="Place Order"
      />

      <div v-if="pricingEnabled() && authCustomerOrganization.is_pro_account" class="text-center">
        <Typography variant="body">
          Credit Balance:
          <strong>
            {{ formatCents(authCustomer.credit_balance_amount, tenant.company.currency) }}
          </strong>
        </Typography>
      </div>

      <div v-if="authCustomerOrganization.is_pro_account">
        <hr class="h-1 mt-3" />
      </div>
    </div>

    <div class="space-y-6">
      <SidebarSection
        v-for="section in useNavigation().sections.filter((section) => section.show)"
        :key="section.name"
        :name="section.name"
      >
        <SidebarItem
          v-for="item in section.children.filter((item) => item.show && item.targetRouteName)"
          :key="item.name"
          :name="item.name"
          :image="item.image"
          :icon-component="item.icon"
          :target-route-name="item.targetRouteName"
          :target-route-attributes="item.targetRouteAttributes"
          :is-inertia-link="item.routeIsInertia"
          :route-names-to-check-if-current="item.routeNamesToCheckIfCurrent"
          :ghost="item.ghost || false"
          :badge="item.badge"
          item-of-primary-column
        />
      </SidebarSection>
    </div>

    <div class="flex-col space-y-6 md:hidden">
      <hr class="h-1 mx-6" />

      <SidebarSection name="Current Group" :use-negative-margin="false">
        <CustomerDropdown :dark-mode="false" />
      </SidebarSection>

      <hr class="h-1 mx-6" />

      <SidebarSection :use-negative-margin="false">
        <form method="post" :action="route('web.customer.login.destroy')" class="h-full">
          <CsrfToken />
          <button
            type="submit"
            class="flex items-center justify-center w-full px-3 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md hover:text-gray-900 hover:bg-gray-50 group"
          >
            <LogoutIcon
              class="w-5 h-5 mr-3 text-gray-500 shrink-0 group-hover:text-gray-600"
              aria-hidden="true"
            />
            Logout
          </button>
        </form>
      </SidebarSection>
    </div>
  </div>
</template>

<script setup>
import { Button, Typography } from '@aryeo/runway'
import { LogoutIcon } from '@heroicons/vue/outline'

import CustomerDropdown from '@/Components/Customer/Header/CustomerDropdown.vue'
import SidebarItem from '@/Components/Customer/Sidebar/SidebarItem.vue'
import CsrfToken from '@/Components/Shared/CsrfToken.vue'
import SidebarSection from '@/Components/Shared/Navigation/Sidebar/SidebarSection.vue'

import { useAuth } from '@/Hooks/Customer/useAuth.js'
import useNavigation from '@/Hooks/Customer/useNavigation.js'
import { useTenant } from '@/Hooks/Shared/useTenant.js'

import { formatCents } from '@/Utils/CurrencyFormatter.js'
import { pricingEnabled } from '@/Utils/useCompanySettings.js'

const { tenant } = useTenant()
const { authCustomer, authCustomerOrganization } = useAuth()
</script>
