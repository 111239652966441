<script setup>
import { computed, ref } from 'vue'
import { FormRadioInputs, FormRadioInput, Modal, Stack } from '@aryeo/runway'

import { useTenant } from '@/Hooks/Shared/useTenant.js'

const props = defineProps({
  customerTeamMemberships: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['onClose', 'onConfirm'])

const { tenant } = useTenant()

const currentDefaultMembership = computed(() => {
  return props.customerTeamMemberships.find((customerTeamMembership) => {
    return customerTeamMembership.is_default
  })
})

const selectedDefaultMembership = ref(currentDefaultMembership.value.id)

const handleCloseClick = () => emit('onClose')
const handleConfirmClick = () => emit('onConfirm', selectedDefaultMembership.value)

const buildLabel = (customerTeamMembership) => {
  return `${customerTeamMembership.customer_team.name} (${customerTeamMembership.role})`
}
</script>

<template>
  <Teleport to="body">
    <Modal
      titleText="Change default workspace"
      cancelButtonText="Cancel"
      confirmButtonText="Update"
      :isLoading="isLoading"
      :isOpen="isOpen"
      @onCancel="handleCloseClick"
      @onClose="handleCloseClick"
      @onConfirm="handleConfirmClick"
    >
      <Stack class="mt-6" space="small">
        <FormRadioInputs
          label="Select your default workspace."
          :helperText="`This will be the workspace you are directed to when you sign into the ${tenant.company.name} portal.`"
        >
          <FormRadioInput
            v-for="customerTeamMembership in customerTeamMemberships"
            :key="customerTeamMembership.id"
            name="type"
            :value="selectedDefaultMembership == customerTeamMembership.id"
            :isSelected="selectedDefaultMembership == customerTeamMembership.id"
            :label="buildLabel(customerTeamMembership)"
            @update:modelValue="selectedDefaultMembership = customerTeamMembership.id"
          />
        </FormRadioInputs>
      </Stack>
    </Modal>
  </Teleport>
</template>
